import React from 'react';
import Layout from '../layout/layout';

import IllustrationMailBox from '../images/icons/contact-confirmation/illustration-mailbox-2.svg';
import IllustrationCloud from '../images/icons/contact-confirmation/illustration-sun.svg';
import IllustrationTrees from '../images/icons/contact-confirmation/illustration-trees-2.svg';

const returnBack = () => window.history.back();

const NewsletterConfirmation = () => (
    <Layout invertHeader={true}>
        <section className="newsletter-confirmation">
            <div className="container text-left">
                <div className="row">
                    <div className="col-sm-7 col-md-7 col-lg-8 offset-sm-2 offset-md-3 offset-lg-3">
                        <h3>
                            Thank you for contacting Common App. A member of our team will be in touch with you soon.
                        </h3>
                        <button onClick={() => returnBack()} className="btn btn-sm btn-blue">Return to the previous page</button>
                    </div>
                </div>
                <img src=/{IllustrationMailBox} className="illustration-mail-box-2" alt="mail box" />
                <img src=/{IllustrationCloud} className="illustration-sun" alt="sun" />
                <img src=/{IllustrationTrees} className="illustration-trees-2" alt="trees" />
            </div>
        </section>
    </Layout>
)

export default NewsletterConfirmation;
